<template>
  <div class="tinymce-editor">
    <Editor v-model="myValue" :init="init" :disabled="disabled" ref="editorRef"
            :key="timestamp" @onClick="onClick">
    </Editor>
  </div>
</template>
<script>
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver/theme'
import 'tinymce/icons/default/icons.min'
// 列表插件
import 'tinymce/plugins/lists'
import 'tinymce/plugins/advlist'
// 上传图片插件
import 'tinymce/plugins/image'
import 'tinymce/plugins/imagetools'
// 表格插件
import 'tinymce/plugins/table'
// 自动识别链接插件
import 'tinymce/plugins/autolink'
// 预览插件
import 'tinymce/plugins/preview'
import {uploadContentTextImg} from "@/api/request";

export default {
  name: 'richTextEditor',
  components: {
    Editor
  },
  props: {
    // 传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    menubar: { // 菜单栏      
      type: String,
      default: 'file edit insert view format table'
    },
    // 相关插件配置
    plugins: {
      type: [String, Array],
      default:
          'lists advlist image imagetools table autolink preview paste'
    },
    // 工具栏配置
    toolbar: {
      type: [String, Array],
      default:
          'undo redo |  formatselect | fontsizeselect | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image table preview'
    },
    // 富文本高度
    height: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      // 当前时间戳,是为了解决回显问题
      timestamp: 0,
      //初始化配置
      init: {
        language_url: '/tinymce/langs/zh-Hans.js',
        language: 'zh-Hans',
        skin_url: '/tinymce/skins/ui/oxide',
        height: this.height,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false,
        force_br_newlines : true,
        force_p_newlines : false,
        forced_root_block : '',
        content_style: 'img{width:100%;height:auto;}',
        paste_data_images: true,
        // 配置了此方法,即可手动选择图片
        images_upload_handler: function (blobInfo, success, failure) {
          let params = {
            file: blobInfo.blob(),
            adminId: JSON.parse(localStorage.getItem("userInfo")).adminId
          }
          uploadContentTextImg(params).then(res => {
            if (res.success) {
              // success("https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg");
              success("https://cdn.7li.club/" + res.key);
            } else {
              failure();
            }
          })
        },
        paste_preprocess: function(plugin,args){
        },
        resize: false,
      },
      myValue: this.value
    }
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    //可用的事件参照文档=> https://github.com/tinymce/tinymce-vue
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    clear() {
      this.myValue = ''
    },
    // 解决切换页签后数据回显问题
    setTinymceContent() {
      setTimeout(() => {
        this.timestamp = new Date().getTime()
      }, 10)
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.myValue = newValue;
      },
      immediate: true
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
