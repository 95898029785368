<template>
  <div class="space-query">
    <el-form ref="form" :model="form" label-width="100px">
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="游戏id">
            <el-input disabled v-model="form.game_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏名称">
            <el-input v-model="form.game_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏编码">
            <el-input disabled v-model="form.game_code"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="游戏简称">
            <el-input v-model.trim="form.game_short_name" placeholder="请输入游戏名称拼音缩写"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏类型">
            <el-select v-model="form.game_type">
              <el-option label="请选择游戏类型" value=""></el-option>
              <el-option label="H5" value="1"></el-option>
              <el-option label="手游" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏类别">
            <el-select v-model="form.game_topic">
              <el-option v-for="item in gameTopicData" :key="item.topic_id" :label="item.topic_name" :value="item.topic_id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="游戏状态">
            <el-select v-model="form.game_status">
              <el-option label="请选择游戏状态" value=""></el-option>
              <el-option label="调试" value="1"></el-option>
              <el-option label="上线" value="2"></el-option>
              <el-option label="下线" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏系统">
            <el-select v-model="form.game_system">
              <el-option label="请选择游戏系统" value=""></el-option>
              <el-option label="安卓" value="1"></el-option>
              <el-option label="IOS" value="2"></el-option>
              <el-option label="双端" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏厂商">
            <el-input v-model.trim="form.game_of"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="排序值">
            <el-input type="number" v-model.number="form.sort_num" :min="1" label="描述文字" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏标签">
            <div class="tag-input">
              <el-input size="small" v-model="tagList[0]"/>
              <el-input size="small" v-model="tagList[1]"/>
              <el-input size="small" v-model="tagList[2]"/>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏登录key">
            <el-input disabled v-model="form.game_login_key" label="游戏登录key" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <el-col :span="8">
          <el-form-item label="游戏支付key">
            <el-input disabled v-model="form.game_pay_key" label="游戏支付key" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏登录地址">
            <el-input v-model.trim="form.game_login_url" label="游戏登录地址" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="游戏支付地址">
            <el-input v-model.trim="form.game_pay_url" label="游戏支付地址" />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row v-show="form.game_type === '2' && (form.game_system === '2' || form.game_system === '3')">
        <el-col :span="12">
          <el-form-item label="IOS签名">
            <el-select v-model="form.ios_sign_type">
              <el-option label="个人" value="1"></el-option>
              <el-option label="企业" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="8">
          <el-form-item label="是否隐藏">
            <el-select v-model="form.show_flag">
              <el-option label="展示" value="1"></el-option>
              <el-option label="隐藏" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="游戏简介">
            <el-input v-model.trim="form.game_desc"
                      type="textarea"
                      :autosize="{ minRows: 2, maxRows: 6}"
                      placeholder="请输入游戏简介"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <div style="width: 160px;margin: 0 auto">
          <el-button style="" type="primary" @click="submitForm('form')">提交</el-button>
          <el-button @click="$router.push({name: 'GameList'})">取消</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {queryGameByGameId, queryGameTopic, updateGame} from "@/api/request";

export default {
  name: "GameAdd",
  data(){
    return {
      form: {
        game_id: '',
        game_name: '',
        game_of: '',
        game_type: '',
        game_topic: '',
        game_status: '',
        game_system: '',
        game_short_name: '',
        game_desc: '',
        ios_sign_type: '',
        sort_num: '',
        game_code: '',
        tag: '',
        game_login_key: '',
        game_pay_key: '',
        show_flag: '',
        game_login_url: '',
        game_pay_url: '',
      },
      dialogVisible: false,
      gameId: '',
      tagList: [],
      gameTopicData: [],
    }
  },
  mounted() {
    this.gameId = this.$route.params.pathMatch;
    this.loadGame(this.gameId);
    this.getGameTopic();
  },
  methods: {
    loadGame(gameId){
      queryGameByGameId(gameId).then(res => {
        const result = res.data.data;
        if(result.tag){
          this.tagList = result.tag.split(",");
        }
        
        this.form.game_id = result.game_id.toString();
        this.form.game_name = result.game_name.toString();
        this.form.game_status = result.game_status.toString();
        this.form.game_system = result.game_system.toString();
        this.form.game_topic = result.game_topic.toString();
        this.form.game_type = result.game_type.toString();
        this.form.game_desc = result.game_desc.toString();
        this.form.game_of = result.game_of.toString();
        this.form.game_short_name = result.game_short_name.toString();
        this.form.sort_num = result.sort_num.toString();
        this.form.game_code = result.game_code.toString();
        this.form.game_login_key = result.game_login_key.toString();
        this.form.game_pay_key = result.game_pay_key.toString();
        this.form.show_flag = result.show_flag.toString();
        if(result.game_login_url){
          this.form.game_login_url = result.game_login_url.toString();
        }
        if(result.game_pay_url){
          this.form.game_pay_url = result.game_pay_url.toString();
        }
        if(result.ios_sign_type){
          this.form.ios_sign_type = result.ios_sign_type.toString();
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.joinTagList();
          updateGame(this.form).then(res => {
            if(res.data.success){
              this.$message.success("更新成功");
              this.loadGame(this.gameId);
            }else {
              this.$message.error(res.data.error.message);
            }
          })
        } else {
          return false;
        }
      });
    },
    getGameTopic(){
      queryGameTopic().then(res => {
        res.data.data.forEach(e => {
          let item = {};
          item.topic_id = e.topic_id.toString();
          item.topic_name= e.topic_name;
          this.gameTopicData.push(item);
        })
      })
    },
    joinTagList(){
      this.form.tag = '';
      this.form.tag = this.tagList.filter(f => f).join(',');
    }
  }
}
</script>

<style scoped>
  .space-query {
    display: flex;
    justify-content: center;
    min-width: 900px;
  }

  .space-query /deep/ .el-input {
    width: 200px;
  }
  
  .tag-input{
    width: 210px;
    display: grid;
    grid-template-columns: repeat(3,30%);
    column-gap: 5px;
  }
  .tag-input .el-input {
    width: 100%;
  }
</style>